import React from 'react';
import StacksLogo from '../../../../assets/images/connect/web3/stacks.svg';

function ClientsStatsSection() {
  return (
    <section className="clients-stats-section">
      <h1 className="section-heading mb-4">Our Featured Clients</h1>
      <p className="section-description mb-3">
        We are fortunate to work with some of the most up and coming protocols for the most{' '}
        <span className="heading-title__blue latoBoldMedium">recognized brands</span> in the
        industry. Our highly{' '}
        <span className="heading-title__blue latoBoldMedium">technical team</span> has successfully
        delivered projects that are in use by{' '}
        <span className="heading-title__blue latoBoldMedium">millions</span> of our{' '}
        <span className="heading-title__blue latoBoldMedium">partner's customers.</span>
      </p>
      <div className="stats-card-container pt-lg-3">
        <div className="stats-card">
          <img className="mb-3" src={StacksLogo} alt="stacks logo" />
          <div className="card-description-stat mb-3">$440M</div>
          <div className="card-description">Market Cap (approx.)</div>
        </div>
        <div className="stats-card">
          <div className="card-description mb-3 latoBold">Top Cryptocurrency Exchange</div>
          <div className="card-description-stat mb-3">$17 Billion</div>
          <div className="card-description">Market Cap (approx.)</div>
        </div>
        <div className="stats-card">
          <div className="card-description mb-3 latoBold">Top Decentralized Oracle Network</div>
          <div className="card-description-stat mb-3">$7.6 Billion</div>
          <div className="card-description">Market Cap (approx.)</div>
        </div>
        <div className="stats-card">
          <div className="card-description mb-3 latoBold">Top Crypto Custody Firm</div>
          <div className="card-description-stat mb-3">$1 Billion</div>
          <div className="card-description">Market Cap (approx.)</div>
        </div>
      </div>
    </section>
  );
}

export default ClientsStatsSection;
