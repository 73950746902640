import React from 'react';
import ProcessHeader from '../../../../assets/images/connect/web3/process-header.svg';
import Accordion from '../../../Shared/Accordion';

function ProcessSection() {
  return (
    <section className="process-section">
      <h1 className="section-heading mb-5">Our Process</h1>
      <div className="process-table-container">
        <div className="title-container">
          <img className="title-img" src={ProcessHeader} alt="Product Analysis" />
        </div>
        <div className="goal-container">
          <div className="goal-card">
            <h2 className="card-title">Goal</h2>
            <ul className="card-description pl-4">
              <li>To intro with team and align on the product roadmap.</li>
            </ul>
          </div>
          <div className="goal-card">
            <h2 className="card-title">Goal</h2>
            <ul className="card-description pl-4">
              <li>To complete the agreed Scope of work with-in expected timeline.</li>
            </ul>
          </div>
          <div className="goal-card border-0">
            <h2 className="card-title">Goal</h2>
            <ul className="card-description pl-4">
              <li>Knowledge Transfer to your team.</li>
            </ul>
          </div>
        </div>
        <div className="deliverables-container">
          <div className="deliverables-card">
            <h2 className="card-title">Deliverables</h2>

            <ul className="card-description pl-4">
              <li>Gantt chart of feature breakdown with a proposed team structure.</li>
            </ul>
          </div>
          <div className="deliverables-card">
            <h2 className="card-title">Deliverables</h2>

            <ul className="card-description pl-4">
              <li>PRs and documentation for completing the feature</li>
            </ul>
          </div>
          <div className="deliverables-card border-0">
            <h2 className="card-title">Deliverables</h2>

            <ul className="card-description pl-4">
              <li>Knowledge Transfer sessions, sequence diagrams and retro documents</li>
            </ul>
          </div>
        </div>
        <div className="outcome-container">
          <div className="outcome-card">
            <h2 className="card-title">Outcome</h2>
            <ul className="card-description pl-4">
              <li>Estimated timeline and monthly burn.</li>
            </ul>
          </div>
          <div className="outcome-card">
            <h2 className="card-title">Outcome</h2>
            <ul className="card-description pl-4">
              <li>Completed scope of work</li>
            </ul>
          </div>
          <div className="outcome-card border-0">
            <h2 className="card-title">Outcome</h2>
            <ul className="card-description pl-4">
              <li>Tacit knowledge preserved</li>
            </ul>
          </div>
        </div>
        <div className="format-container">
          <div className="format-card">
            <h2 className="card-title">Format</h2>
            <ul className="card-description pl-4">
              <li>
                Three video calls in two weeks, one for understanding the feature and another one
                for asking questions and sharing the outcome.
              </li>
            </ul>
          </div>
          <div className="format-card">
            <h2 className="card-title">Format</h2>
            <ul className="card-description pl-4">
              <li>Weekly sprints and updates</li>
              <li>Daily workloads</li>
              <li>Bi-weekly alignment & update calls</li>
              <li>Async comms for blockers</li>
            </ul>
          </div>
          <div className="format-card border-0">
            <h2 className="card-title">Format</h2>
            <ul className="card-description pl-4">
              <li>Video calls for live Q&A and recording.</li>
              <li>Async answer to questions in channels.</li>
            </ul>
          </div>
        </div>
        <div className="format-container border-0">
          <div className="format-card">
            <h2 className="card-title">
              Duration <span className="latoRegular">: 2 Weeks</span>
            </h2>
          </div>
          <div className="format-card ">
            <h2 className="card-title">
              Duration <span className="latoRegular">: Provided estimated timeline</span>
            </h2>
          </div>
          <div className="format-card border-0">
            <h2 className="card-title">
              Duration <span className="latoRegular">: 2 Weeks</span>
            </h2>
          </div>
        </div>
      </div>
      <div className="process-accordion-container">
        <div className="wrapper">
          <Accordion index={1} title="1. Product Scoping" customClass="">
            <h2 className="card-title">Goal</h2>
            <ul className="card-description pl-4">
              <li>To intro with team and align on the product roadmap.</li>
            </ul>

            <h2 className="card-title">Deliverables</h2>

            <ul className="card-description pl-4">
              <li>Gantt chart of feature breakdown with a proposed team structure.</li>
            </ul>

            <h2 className="card-title mt-4">Outcome</h2>
            <ul className="card-description pl-4">
              <li>Estimated timeline and monthly burn.</li>
            </ul>

            <h2 className="card-title mt-4">Format</h2>
            <ul className="card-description pl-4">
              <li>
                Three video calls in two  weeks, one for understanding the feature and another one
                for asking questions and sharing the outcome.
              </li>
            </ul>

            <h2 className="card-title">
              Duration <span className="latoRegular">: 2 Weeks</span>
            </h2>
          </Accordion>
          <Accordion index={2} title="2. Weekly Sprints" customClass="accordion-title-2">
            <h2 className="card-title">Goal</h2>
            <ul className="card-description pl-4">
              <li>To complete the agreed Scope of work with-in expected timeline.</li>
            </ul>

            <h2 className="card-title">Deliverables</h2>

            <ul className="card-description pl-4">
              <li>PRs and documentation for completing the feature.</li>
            </ul>

            <h2 className="card-title mt-4">Outcome</h2>
            <ul className="card-description pl-4">
              <li>Completed scope of work.</li>
            </ul>

            <h2 className="card-title mt-4">Format</h2>
            <ul className="card-description pl-4">
              <li>Weekly sprints and updates</li>
              <li>Daily worklogs</li>
              <li>Bi-weekly alignment & update calls</li>
              <li>Async comms for blockers</li>
            </ul>

            <h2 className="card-title">
              Duration <span className="latoRegular">: Provided estimated timeline</span>
            </h2>
          </Accordion>
          <Accordion index={3} title="3. Knowledge Transfer" customClass="accordion-title-3">
            <h2 className="card-title">Goal</h2>
            <ul className="card-description pl-4">
              <li>Knowledge Transfer to your team.</li>
            </ul>

            <h2 className="card-title">Deliverables</h2>

            <ul className="card-description pl-4">
              <li>Knowledge Transfer sessions, sequence diagrams and Retro documents.</li>
            </ul>

            <h2 className="card-title mt-4">Outcome</h2>
            <ul className="card-description pl-4">
              <li>Tacit knowledge preserved and transferred.</li>
            </ul>

            <h2 className="card-title mt-4">Format</h2>
            <ul className="card-description pl-4">
              <li>Video calls for live Q&A and recording.</li>
              <li>Async answer to questions in channels.</li>
            </ul>

            <h2 className="card-title">
              Duration <span className="latoRegular">: 2 Weeks</span>
            </h2>
          </Accordion>
        </div>
      </div>
    </section>
  );
}

export default ProcessSection;
