import React from 'react';
import {Link as GatsbyLink} from 'gatsby';
import Link from '../../../Shared/Link/Link';
import tintashLogoSrc from '../../../../assets/images/connect/web3/tintash-logo.svg';
import headingImage from '../../../../assets/images/connect/web3/heading-image.svg';

function HeadingSection() {
  return (
    <section className="live-ops__main heading-section">
      <GatsbyLink to="/" className="heading-link">
        <img src={tintashLogoSrc} alt="Tintash Logo" />
      </GatsbyLink>
      <div className="heading-container">
        <div className="heading-subsection">
          <div className="heading-title">
            Experience of Working with{' '}
            <span className="heading-title__blue">6 Web 3.0 Unicorns </span>
          </div>
          <p className="heading-description mb-lg-4">
            We have helped <span className="latoBold">6 Unicorns</span> surpass their annual product
            roadmaps. And we can do it for you.
          </p>
          <Link className="heading-btn" to="/contact">
            Contact Us
          </Link>
        </div>
        <div className="heading-image-container">
          <img className="heading-image" src={headingImage} alt="heading" />
        </div>
      </div>
    </section>
  );
}

export default HeadingSection;
