import React from 'react';
import AccordionIcon from '../../../assets/images/connect/web3/accordion-icon.svg';

export default function Accordion({index, title, children, customClass}) {
  const [isOpen, setOpen] = React.useState(index === 1);
  return (
    <div className="accordion-wrapper">
      <div
        className={`accordion-title ${customClass} ${isOpen ? 'open' : ''}`}
        onClick={() => setOpen(!isOpen)}
      >
        {title}
        <img
          className={`accordion-icon ${!isOpen ? 'rotate' : ''}`}
          src={AccordionIcon}
          alt="Accordion Icon"
        />
      </div>
      <div className={`accordion-item ${!isOpen ? 'collapsed' : ''}`}>
        <div className="accordion-content">{children}</div>
      </div>
    </div>
  );
}
