import React from 'react';

function SolutionsSection() {
  return (
    <section className="solutions-section">
      <h1 className="section-heading mb-5">Solutions</h1>
      <div className="solutions-card-container">
        <div className="solutions-card">
          <h2 className="solutions-card-title">Resolve Ambiguity</h2>
          <p className="solutions-card-description">
            Let our team of experts deal with uncertainties  and to provide a product roadmap and a
            proposed team structure.
          </p>
          <p className="solutions-card-description">
            <strong>
              Our $5,000 product scoping exercise delivers a comprehensive roadmap to deal with
              ambiguity
            </strong>
            , leveraging our 15+ years of expertise in designing and implementing delightful UX that
            sticks.
          </p>
        </div>
        <div className="solutions-card">
          <h2 className="solutions-card-title">Accelerate Roadmap</h2>
          <p className="solutions-card-description">
            Work with a truly Agile team, that has a history of delivering complete product roadmaps
            for Web3 Unicorns
          </p>
          <p className="solutions-card-description">
            <strong>Our $40,000/month managed team, led by an experience EM</strong>, handles
            end-to-end scoping and delivery of product roadmap items, followed by complete hand-off
            and knowledge transfer.
          </p>
        </div>
      </div>
    </section>
  );
}

export default SolutionsSection;
