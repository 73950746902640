import React from 'react';
import '../../../assets/css/connect.css';
import '../../../assets/css/live-ops.css';
import Footer from '../../Shared/Footer';
import HeadingSection from './Section/HeadingSection';
import ClientsLogoSection from './Section/ClientsLogoSection';
import SolutionsSection from './Section/SolutionsSection';
import ProcessSection from './Section/ProcessSection';
import CaseStudySection from './Section/CaseStudySection';
import ClientsStatsSection from './Section/ClientsStatsSection';

function Web3Template() {
  return (
    <>
      <HeadingSection />
      <ClientsStatsSection />
      <ClientsLogoSection />
      <SolutionsSection />
      <ProcessSection />
      <CaseStudySection />
      <Footer showContactSection={false} showPortfolioSection={false} />
    </>
  );
}

export default Web3Template;
