import React from 'react';
import CommaIcon from '../../../../assets/images/connect/web3/comma-icon.svg';
import BackgroundImage from '../../../../assets/images/connect/web3/case-study.svg';

function CaseStudySection() {
  return (
    <section className="case-study-section">
      <h1 className="section-heading mb-5">Case Study</h1>
      <div className="case-study-container">
        <img className="case-study-background-image" src={BackgroundImage} alt="case study" />
        <div className="title-subsection">
          <h1 className="case-study-title m-0">
            A leading cryptocurrency exchange platform based in the US
          </h1>
          <div className="case-study-testimonial">
            <img className="testimonial-comma-icon" src={CommaIcon} alt="Comma Icon" />
            <p className="testimonial-description">
              “Tintash has a super power and that is training and onboarding squads.”
            </p>
            <p className="testimonial-name">Senior Engineering Manager</p>
          </div>
        </div>
        <div className="metric-subsection">
          <div className="goal-subsection">
            <div className="metric-title">Client Business Problem</div>
            <div className="metric-description">
              Looking for experts in <span className="latoBold">Rosetta API Specifications</span> to
              help them accelerate their{' '}
              <span className="latoBold">asset integrations and enablement.</span>
            </div>
            <div className="metric-title mt-4">Before</div>
            <div className="metric-description">
              Incoherent Rosetta implementations leading{' '}
              <span className="latoBold">up to 4 months</span> in development and release of every
              new blockchain.
            </div>
          </div>

          <div className="outcome-subsection">
            <div className="metric-title">Outcome Achieved</div>
            <ul className="metric-description pl-4">
              <li className="mb-3">
                Our expertise and streamlined processes brought the launch time of some of the new
                blockchains <span className="latoBold">down to less than 1 month.</span>
              </li>
              <li className="mb-3">
                Delivered complete Rosetta implementation in{' '}
                <span className="latoBold">just 3 weeks.</span>
              </li>
              <li className="mb-3">
                Enabled an asset in <span className="latoBold">quickest ever time</span> in the
                client’s entire experience of asset integrations.
              </li>
              <li>
                Helped more than a <span className="latoBold">dozen asset issuers</span> with
                Rosetta implementations for smooth integration.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CaseStudySection;
