import React from 'react';

const CLIENTS_LOGO_DATA = [
  {
    src: require('../../../../assets/images/connect/web3/xverse.svg').default,
    alt: 'Xverse',
  },
  {
    src: require('../../../../assets/images/connect/web3/magic-eden.svg').default,
    alt: 'Magic Eden',
  },
  {
    src: require('../../../../assets/images/connect/web3/alex.svg').default,
    alt: 'AlexGo',
  },
  {
    src: require('../../../../assets/images/connect/web3/stackswap.svg').default,
    alt: 'StackSwap',
  },
];

function ClientsLogoSection() {
  return (
    <section className="live-ops__main">
      <div className="clients-logo-container mt-0">
        {CLIENTS_LOGO_DATA.map(({src, alt}) => (
          <img key={src} className="clients-logo-img" src={src} alt={alt} />
        ))}
      </div>
    </section>
  );
}

export default ClientsLogoSection;
